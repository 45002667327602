import { Component, OnInit } from '@angular/core';
import {SportsServiceService} from '../../../services/sports-service.service';
import {PdcService} from '../../../shared/pdcService';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})

export class SidebarComponent implements OnInit {

  constructor( public Sportservice: SportsServiceService, public pdcService: PdcService) { }
  
  hiddenReports:any;
  hiddenSport:any;
  hiddenSport1:any;
  hiddenUser:any;
  userDetails:any;
  is_show_comission;
  permission:any=[];
  pdcData;
  
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('AdminLoginData'));
    this.permission=this.userDetails.permission;
    this.hiddenSport = false;
    this.hiddenSport1 = false;
    this.hiddenUser=false;
    this.hiddenReports=false;
    this.globalConstant();
  }

  addMenuClass() {
    $("body").toggleClass("addusermenu");
    $("body").removeClass("addleftcls");
  }

  globalConstant() {
    this.Sportservice.globalConstant().subscribe(response => {
      this.is_show_comission = response.data.super_admin_commission_type;
      this.pdcService.PdcData = response.data;
    }, error => {
    });
  }

  getPermission(value) {
    if( this.permission != undefined){
      if(this.permission.indexOf(value) !== -1){
        return true;
      } else{
        return false
      }
    }else{
      return true;
    }
  }

}
