import { Component, OnInit } from '@angular/core';
import {UserServiceService} from '../../../services/user-service.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../services/session.service';
import {resolve} from 'q';
import {environment} from '../../../../environments/environment.prod';
declare var $: any;
import {ToastrManager} from 'ng6-toastr-notifications';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  constructor(public userService: UserServiceService, public router:Router, public sessionService:SessionService, public toastr: ToastrManager) { }

  balanceData:any=[];
  Liability:any;
  Balance:any;
  FreeChips:any;
  P_L:any;
  userDetails:any;
  logo:any;
  message;
  old_password;
  newpassword;
  Renewpassword;
  loginUserData1;
  permission:any=[];

  ngOnInit() {
    this.logo=this.sessionService.baseurl + 'uploads/logo.png';
    this.userDetails = JSON.parse(localStorage.getItem('AdminLoginData'));
    this.permission=this.userDetails.permission;
    this.getChipDatabyId();
    if(this.userDetails.is_change_password == '1')
      $('#changePass').show();
  }

  logout() {
    if (confirm("Are you sure want to logout ")) {
      this.userService.logout().subscribe(response => {
        if (!response.error) {
          this.sessionService.destroy('AdminLoginData');
          this.sessionService.destroy('marketVal');
          this.sessionService.destroy('LoggedInUser');
          this.sessionService.destroy('fancyVal');
          this.router.navigate(['']);
        } else {
        }
      }, error => {
      });
    }
  }

  leftMenuOpen(){
    if ($("body").hasClass("addleftmenu")) {
      $('body').removeClass('addleftmenu');
    }else{
      setTimeout(() => {
        $("body").toggleClass("addleftmenu");
      }, 200);
    }
  }

  changePassword () {
    this.loginUserData1 = {
      "oldPassword": this.old_password,
      "newPassword": this.newpassword,
      "confirmNewPassword":this.Renewpassword
    };

    this.userService.updatePassword(this.loginUserData1).subscribe((result) => {
      this.message = result.message;
      if(!result.error){
        this.userDetails.is_change_password='0';
        localStorage.setItem('AdminLoginData',JSON.stringify(this.userDetails));
        $('#changePass').hide();
      }else {
        this.toastr.errorToastr(result.message);
      }

    }, (err) => {
      console.log(err);
    });
  }

  getChipDatabyId() {
    this.userService.getChipDatabyId().subscribe((data) => {
          if(!data.error) {

            if(data.data != undefined){
          this.balanceData=data.data;
          this.Balance = data.data.balance;
          this.FreeChips = data.data.freechips;
          this.Liability = data.data.liability;
          this.P_L = data.data.profit_loss;
          if (data.data.lock_user == '1' || data.data.close_account == '1' || data.data.is_online == '0') {
            this.logout1();
          }
        }
    }else if(data.code == 412){
      localStorage.clear();
      window.location.reload();

      this.router.navigate(['login']);

    }

      }, (err) => {
        console.log(err);
          if (this.router.url.split('?')[0] != '/login') {
            setTimeout(() => resolve(this.getChipDatabyId()), 4000);
          }
      },
      () => {
        if (this.router.url.split('?')[0] != '/login') {
          setTimeout(() => resolve(this.getChipDatabyId()), 4000);
        }
      });
  }

  logout1() {
    this.userService.logout().subscribe((result) => {
      localStorage.AdminLoginData=null;
      this.router.navigate(['login']);

    }, (err) => {
      console.log(err);
    });
  }

  getPermission(value) {
    if( this.permission != undefined){
      if(this.permission.indexOf(value) !== -1){
        return true;
      } else{
        return false
      }
    }else{
      return true;
    }
  }

}
