import {Component, OnInit} from '@angular/core';
import {UserServiceService} from '../../../services/user-service.service';
import {SportsServiceService} from '../../../services/sports-service.service';
import {FormBuilder, FormControl, NgForm, FormGroup, Validators} from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;

@Component({
  selector: 'app-rightbar',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.sass']
})
export class RightbarComponent implements OnInit {
  userId: any;
  currentUserDetails: any;
  userName: any;
  userPassword: any;
  userMaxProfit: any;
  userMaxLoss: any;
  userCreateNoChild: any;
  userSessMinStake: any;
  userRemark: any;
  userOddMaxStake: any;
  userSessComm: any;
  userMatComm: any;
  userSessMaxStake: any;
  userOddMinStake: any;
  message: any;
  userPass: any;
  userConfPass: any;
  rightVal: any;
  userDetails: any;
  selectedItem: any;
  creatUserFor: any;
  checkMsg: any;
  creatUserOf: any;
  sportsettingId: any;
  partnershipSportData: any;
  accChips: any;
  accChipsComment: any;
  IsShowTreeDiv: any;
  marketSettData: any;
  checkMessage: any;
  showMsg: any;
  checkMessageError: any;
  userList: [];
  createForm: FormGroup;
  chipForm: FormGroup;
  changePassForm: FormGroup;
  closeCreateForm: FormGroup;

  constructor(public toastr: ToastrManager,private  userservice: UserServiceService, public sportService: SportsServiceService, private formBuilder: FormBuilder) {
    this.createForm = formBuilder.group({
      userId: ['', Validators.required],
      name: ['', Validators.required],
      password: ['', Validators.required],
      userCreateNoChild: new FormControl(),
      maxProfit: new FormControl(),
      maxLoss: new FormControl(),
      maxStack: new FormControl(),
      minStack: new FormControl(),
      sessionComisssion: new FormControl(),
      matchComisssion: new FormControl(),
      sessionMaxStake: new FormControl(),
      sessionMinStake: new FormControl(),
      userRemark: new FormControl(),
    });

    this.changePassForm = formBuilder.group({
      password: ['', Validators.required],
      confirmpass: ['', Validators.required]
    });

    this.chipForm = formBuilder.group({
      accChips: new FormControl(),
      accChipsComment: new FormControl(),
    });
  }

  ngOnInit() {
    this.showMsg=false;
    this.userMaxProfit = null;
    this.userMaxLoss = null;
    this.userCreateNoChild = 0;
    this.userSessMinStake = null;
    this.userOddMaxStake = null;
    this.userSessComm = 0;
    this.userMatComm = 0;
    this.userSessMaxStake = null;
    this.userOddMinStake = null;
    this.userRemark = null;
    this.userDetails = JSON.parse(localStorage.getItem('AdminLoginData'));

    this.getOwnChild('');

  }

  addMenuClass() {
    $('body').toggleClass('addusermenu');

    $('body').removeClass('addleftcls');

    $('#right_closebox').hide();


  }

  listClick(event, newValue) {
    //alert("dsfkdshf");
    this.getOwnChild(newValue);
    //console.log(this.userList[0]);
    //return false;
    console.log(newValue);
    this.selectedItem = newValue;
    newValue.showSubfolders = !newValue.showSubfolders;
    event.stopPropagation();


  }

  getOwnChild = function (user) {
    var userdata = {
      'user_id': '' + (user=='' ?'' :user.id)
    };
    this.userservice.getOwnChild(userdata).subscribe(response => {
      this.loading = false;
      if (!response.error) {
        if (this.userList == undefined) {
          this.userList = response.data.data;
          this.rightVal = response.data.data[0];

          this.userList = this.userList.map((element, index) => {
            return {index, ...element, folders: []};
          });

        } else if (user.user_type_id == 0) {
          let ind = this.userList.findIndex(x => x.id == user.id);
          if (ind > -1)
            this.userList[ind].folders = response.data.data;
          this.userList[ind].folders = this.userList[ind].folders.map((element, index) => {
            return {index, ...element, folders: []};
          });
        }
        else if (user.user_type_id == 1) {

          user.folders = response.data.data;
          user.folders = user.folders.map((element, index) => {
            return {index, ...element, folders: []};
          });
          /*   let ind = this.userList[user.index].folders.findIndex(x => x.id == user.id);
             if (ind > -1)
               this.userList[user.index].folders[ind].folders = response.data.data;
             this.userList[user.index].folders[ind].folders = this.userList[user.index].folders[ind].folders.map((element, index) => {
               return {index, ...element, folders: []};
             });*/
        }
        else if (user.user_type_id == 2) {

          user.folders = response.data.data;
          user.folders = user.folders.map((element, index) => {
            return {index, ...element, folders: []};
          });
          /* let ind = this.userList.findIndex(x => x.id == user.id);
           if (ind > -1)
             this.userList[ind].folders = response.data.data;
           this.userList[ind].folders = this.userList[ind].folders.map((element, index) => {
             return {index, ...element, folders: []};
           });*/
        }
        else if (user.user_type_id == 3) {

          user.folders = response.data.data;
          user.folders = user.folders.map((element, index) => {
            return {index, ...element, folders: []};
          });
          /* let ind = this.userList.findIndex(x => x.id == user.id);
           if (ind > -1)
             this.userList[ind].folders = response.data.data;
           this.userList[ind].folders = this.userList[ind].folders.map((element, index) => {
             return {index, ...element, folders: []};
           });*/
        }
        else if (user.user_type_id == 4) {

          user.folders = response.data.data;
          user.folders = user.folders.map((element, index) => {
            return {index, ...element, folders: []};
          });
          /* let ind = this.userList.findIndex(x => x.id == user.id);
           if (ind > -1)
             this.userList[ind].folders = response.data.data;
           this.userList[ind].folders = this.userList[ind].folders.map((element, index) => {
             return {index, ...element, folders: []};
           });*/
        }

        else {
          user.folders = response.data.data;
          user.folders = user.folders.map((element, index) => {
            return {index, ...element, folders: []};
          });
          //this.sessionservice.notifier.notify( 'error', response.message );
        }
      }
    }, error => {
      this.loading = false;
    });
  };

  rightPopup(user) {
    console.log(user);
    this.rightVal = user;
    this.creatUserFor = this.rightVal.name;
    switch (this.rightVal.user_type_id) {
      case 1:
        this.creatUserOf = 'Super Master';
        break;
      case 2:
        this.creatUserOf = 'Master';
        break;
      case 3:
        this.creatUserOf = 'Dealer';
        break;
      case 4:
        this.creatUserOf = 'User';
        break;
    }

    // this.getOwnChild(user.id);
    $('#right_closebox').show();
  };

  addUser = function () {
    this.createForm.reset();
    this.showMsg=false;
    $('#AddAccountPopup').modal('show');
  };
  viewUsers = function () {
    this.ViewCurrentAccount();
    $('#ViewAccountPopup').modal('show');
  };
  changeUsersPass = function() {
    this.changePassForm.reset();
    $('#userChangePassPopup').modal('show');
  };
  userLockPop = function () {
    $('#userLockPopup').modal('show');
  };
  AccChipInOut = function () {
    $('#AccChipInOutPopup').modal('show');
  };
  userUnLockPop = function () {
    $('#userUnLockPopup').modal('show');
  };
  userBettingLock = function () {
    $('#userLockBettingPopup').modal('show');
  };
  userUnLockBettingType = function () {
    $('#userUnLockBettingPopup').modal('show');
  };
  userLockBettingType = function () {
    $('#userLockBettingPopup').modal('show');
  };
  userLockFancyBettingType = function () {
    $('#userLockFancyBettingPopup').modal('show');
  };
  userUnLockFancyBettingType = function () {
    $('#userUnLockFancyBettingPopup').modal('show');
  };
  userClose = function () {
    $('#userCloseAccPopup').modal('show');
  };

  hideRightPopup() {
    $('#right_closebox').hide();
  }

  ViewCurrentAccount() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.getUserBasicDetails(userdata).subscribe(response => {
      if (!response.error) {
        this.currentUserDetails = response.data;
        this.userId = this.currentUserDetails.user_name;
        this.userName = this.currentUserDetails.name;
        this.userCreateNoChild = this.currentUserDetails.create_no_of_child;
        this.userRemark = this.currentUserDetails.remark;
        // alert(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }

  check_user_name(userId) {

    var userdata = {
      'user_name': userId,
    };
    this.userservice.check_user_name(userdata).subscribe(response => {
      if (!response.error) {
        this.showMsg=true;
        this.checkMsg = !response.error;
        this.checkMessage = response.message;
      } else {
        this.showMsg=true;
        this.checkMsg = !response.error;
        this.checkMessageError = response.message;
      }
    }, error => {
    });
  }

  depositOrWithDChips(crdr) {
    var userdata = {
      'user_id': this.rightVal.id,
      'parent_id': this.rightVal.parent_id,
      'crdr': crdr,
      'amount': this.accChips,
      'description': this.accChipsComment

    };
    this.userservice.accountChildInOut(userdata).subscribe(response => {
      if (!response.error) {
        this.accChips = 0;
        this.accChipsComment = '';
        this.toastr.successToastr(response.message);
        this.userList = undefined;
        this.getOwnChild('');
        $('#AccChipInOutPopup').modal('hide');
        $('#right_closebox').hide();


      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }

  userCloseAccStatus() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.updateuserStatusClose(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userCloseAccPopup').modal('hide');
        this.toastr.successToastr(this.message);
      } else {
        return false;
      }
    }, error => {
    });
  };

  addUsers() {
    if (this.createForm.valid) {
      var userDetails = {
        'user_name': this.userId,
        'password': this.userPassword,
        'parent_id': this.rightVal.id,
        'name': this.userName,
        'remark': this.userRemark,
        'user_type_id': (this.rightVal.user_type_id + 1),
        'create_no_of_child': this.userCreateNoChild==undefined?0:this.userCreateNoChild
      };
      this.userservice.addUsers(userDetails).subscribe((data) => {
        // this.message = data.message;
        if (!data.error) {
          this.createForm.reset();
          this.toastr.successToastr(data.message);
          $('#AddAccountPopup').modal('hide');
          this.userMaxProfit = 0;
          this.userMaxLoss = 0;
          this.userCreateNoChild = 0;
          this.userSessMinStake = 0;
          this.userOddMaxStake = 0;
          this.userSessComm = 0;
          this.userMatComm = 0;
          this.userSessMaxStake = 0;
          this.userOddMinStake = 0;
        } else {
          this.toastr.errorToastr(data.message);
        }


      }, (err) => {
        console.log(err);
      });


    }
    else {
      this.toastr.errorToastr('invalid');
    }
  }


  userDetailsUpdate() {
    var userDetails = {
      'user_id': this.rightVal.id,
      'name': this.userName,
      'remark': this.userRemark,
      'create_no_of_child': this.userCreateNoChild
    };
    this.userservice.updateUserBasicDetails(userDetails).subscribe((data) => {
      // this.message = data.message;
      this.toastr.successToastr(data.message);
      $('#ViewAccountPopup').modal('hide');

    }, (err) => {
      console.log(err);
    });
  }

  userChangePassword() {
    var userdata = {
      'childUserId': '' + this.rightVal.id,
      'newPassword': this.userPass,
      'confirmNewPassword': this.userConfPass,
    };
    this.userservice.updateChildPassword(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userChangePassPopup').modal('hide');
        this.toastr.successToastr(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }

  userLockType() {

    if (this.rightVal.lock_user === '0') {
      this.userLock();
    } else {
      this.userUnLock();
    }
  }

  userLock() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.updateuserStatusLock(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userLockPopup').modal('hide');
        $('#right_closebox').hide();
        this.userList = undefined;
        this.getOwnChild('');

        this.toastr.successToastr(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }

  userUnLock() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.updateuserStatusUnlock(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userUnLockPopup').modal('hide');
        $('#right_closebox').hide();
        this.userList = undefined;

        this.getOwnChild('');

        this.toastr.successToastr(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }


  userBettingLocks() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.updateUserStatusBettingLock(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userLockBettingPopup').modal('hide');
        $('#right_closebox').hide();
        this.userList = undefined;

        this.getOwnChild('');


        this.toastr.successToastr(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }

  userBettingUnLock() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.updateUserStatusBettingUnlock(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userUnLockBettingPopup').modal('hide');
        $('#right_closebox').hide();
        this.userList = undefined;

        this.getOwnChild('');


        this.toastr.successToastr(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }
  userFancyBettingLocks() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.updateUserStatusFancyBetLock(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userLockFancyBettingPopup').modal('hide');
        $('#right_closebox').hide();
        this.userList = undefined;

        this.getOwnChild('');


        this.toastr.successToastr(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }

  userFancyBettingUnLock() {
    var userdata = {
      'user_id': this.rightVal.id,

    };
    this.userservice.updateUserStatusFancyBetUnlock(userdata).subscribe(response => {
      if (!response.error) {
        this.message = response.message;
        $('#userUnLockFancyBettingPopup').modal('hide');
        $('#right_closebox').hide();
        this.userList = undefined;

        this.getOwnChild('');


        this.toastr.successToastr(this.message);
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
    });
  }

  opensaveSportSettingPopup() {
    this.sportsettingId = this.rightVal.id;
    this.getUserSettingsBySportID(this.rightVal.id);
    //this.getAllSports();
    $('#SportSettingPop').modal('show');


  }

  opensaveSportPartnerShipSettingPopup() {
    this.sportsettingId = this.rightVal.id;
    this.getUserPartnerShipSettingsBySportID(this.rightVal.id);
    //  this.getAllSports();
    $('#SportPartnerShipSettingPop').modal('show');


  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  getUserSettingsBySportID = function (usrid) {

    this.sportService.getUserSettingsBySportID(this.rightVal.id).subscribe(response => {
      this.loading = false;
      if (!response.error) {
        this.marketSettData = response.data;
        /* this.maxStackSport = this.marketSettData.max_stack
         this.minStackSport =this.marketSettData.min_stack;
         this.sessionDelaySport  =this.marketSettData.volume_limit;
         this.oddsDelaySport  =this.marketSettData.odd_limit;*/
      } else {
        // this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
      this.loading = false;
    });
  };

  getUserPartnerShipSettingsBySportID = function (usrid) {
    var userdata = {
      'user_id': this.rightVal.id,
      'user_type_id': this.rightVal.user_type_id
    };
    this.sportService.getPartnershipList(userdata).subscribe(response => {
      this.loading = false;
      if (!response.error) {
        this.partnershipSportData = response.data;
        /* this.maxStackSport = this.marketSettData.max_stack
         this.minStackSport =this.marketSettData.min_stack;
         this.sessionDelaySport  =this.marketSettData.volume_limit;
         this.oddsDelaySport  =this.marketSettData.odd_limit;*/
      } else {
        // this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
      this.loading = false;
    });
  };
  getAllSports = function () {
    this.sportService.getAllSports().subscribe(response => {
      this.loading = false;
      if (!response.error) {
        this.sportData = response.data;
      } else {
        //this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
      this.loading = false;
    });
  };

  saveSportSetting = function (marketSettData) {

    var data = {
      'user_id': this.rightVal.id,
      'user_type_id': this.rightVal.user_type_id,
      'parent_id': this.rightVal.parent_id, data: marketSettData
    };
    this.sportService.createUserSettings(data).subscribe(response => {
      this.loading = false;
      if (!response.error) {
        this.message = response.message;
        this.toastr.successToastr(this.message);
        $('#SportSettingPop').modal('hide');
      } else {
        this.message = response.message;
        this.toastr.errorToastr(this.message);
        // this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
      this.loading = false;
    });
  };

  saveSportPartnerShipSetting = function (marketSettData) {

    var data = marketSettData;
    this.sportService.updatePartnershipList(data).subscribe(response => {
      this.loading = false;
      if (!response.error) {
        this.message = response.message;

        $('#SportPartnerShipSettingPop').modal('hide');
      } else {
        $('#SportPartnerShipSettingPop').modal('hide');

        this.toastr.successToastr(response.message);
        // this.sessionservice.notifier.notify( 'error', response.message );
      }
    }, error => {
      this.loading = false;
    });
  };

  resetchipForm() {
    this.chipForm.reset();
  }

}
