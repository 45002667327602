import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './views/layout/dashboard/dashboard.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common'
import { LoginComponent } from './views/pages/login/login.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import {RightbarComponent} from './views/partial/rightbar/rightbar.component';
import { HeaderComponent } from './views/partial/header/header.component';
import { SidebarComponent } from './views/partial/sidebar/sidebar.component';
import { FooterComponent } from './views/partial/footer/footer.component';
import {AppRoutingModule} from './routes/app-routing.module';
import {IndexComponent} from './views/layout/index/index.component';
import {PdcService} from './shared/pdcService';




@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    RightbarComponent,
      IndexComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    FormsModule

  ],
  providers: [PdcService,{provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {

}
