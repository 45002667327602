import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginServiceService} from '../../../services/login-service.service';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {SessionService} from '../../../services/session.service';
import {AuthService} from '../../../services/auth.service';
import {ToastrManager} from 'ng6-toastr-notifications';
import {SportsServiceService} from '../../../services/sports-service.service';
declare var $:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})


export class LoginComponent implements OnInit {
  loading: any;
  loginForm: FormGroup;
  isSubmitted = false;
  logo;
  bg_logo;
  g_captcha;
  is_show_comission;

  constructor(public router: Router, private loginservice: LoginServiceService, private formBuilder: FormBuilder, private sessionservice: SessionService,public  authService: AuthService,public toastr:ToastrManager) {
  }

  ngOnInit() {
    this.logo =this.sessionservice.baseurl + 'uploads/logo.png';
    this.bg_logo = this.sessionservice.baseurl + 'uploads/bg_logo.png';
    $.getScript('/assets/js/captcha.js');
    this.g_captcha = localStorage.getItem('adminv3token');
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get formControls() {
    return this.loginForm.controls;
  }

  // UserLogin () {
  //   this.g_captcha = localStorage.getItem('adminv3token');

  //   this.isSubmitted = true;
  //   // stop here if form is invalid
  //   if (this.loginForm.invalid) {
  //     return;
  //   } else {
  //     this.loading = true;
  //     var loginFormvalue = this.loginForm.value;
  //     var data = {
  //       user_name: loginFormvalue.email,
  //       password: loginFormvalue.password,
  //       g_captcha: this.g_captcha,
  //     };
  //     this.loginservice.UserLogin(data).subscribe(response => {
  //       this.loading = false;
  //       if (!response.error) {
  //         this.sessionservice.set('AdminLoginData', JSON.stringify(response.data));
  //         this.authService.sendToken(response.data.token);
  //         this.router.navigate(['dashboard/MarketWatch']);
  //       } else {
  //         this.toastr.errorToastr(response.message);
  //       }
  //     }, error => {
  //       this.loading = false;
  //     });
  //   }
  // }
  UserLogin () {
    this.g_captcha = localStorage.getItem('adminv3token');

    this.isSubmitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loading = true;
      var loginFormvalue = this.loginForm.value;
      var data = {
        user_name: loginFormvalue.email,
        password: loginFormvalue.password,
        g_captcha: this.g_captcha,
      };
      this.loginservice.UserLogin(data).subscribe(response => {
        this.loading = false;
        if (!response.error) {
          this.sessionservice.set('AdminLoginData', JSON.stringify(response.data));
          this.authService.sendToken(response.data.token);
          this.router.navigate(['dashboard/MarketWatch']);
        } else {
          this.toastr.errorToastr(response.message);
        }
      }, error => {
        this.loading = false;
      });
    }
  }

}


