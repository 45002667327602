export const environment = {
    production: true,
      APIEndpoint: 'https://api.crickez.in/api/v1/', 

      // APIEndpoint: window.location.origin +'/api/v1/'
    // APIEndpoint: 'http://192.168.1.102:3003/api/v1/', 
    // APIEndpoint: 'http://192.168.1.24:3306/api/v1/',
    // APIEndpoint: 'https://adch100.lotus24x7.com/api/v1'
    // APIEndpoint: 'http://192.168.1.21:3003/api/v1/',
    //  APIEndpoint:'http://192.168.1.24:3003/api/v1/' 

};
