import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    permission;
    userDetails;

    constructor() {
        this.userDetails = JSON.parse(localStorage.getItem('AdminLoginData'));
        if(this.userDetails != null)
        this.permission = this.userDetails.permission;
    }

    baseurl = environment.APIEndpoint;


    set(key, value) {
        return localStorage.setItem(key, value);
    }

    get(key) {
        return localStorage.getItem(key);
    }

    destroy(key) {
        return localStorage.removeItem(key);
    }

    setHeader() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return headers;
    }

    setTokenHeader() {
        var AdminLoginData = JSON.parse(localStorage.getItem('AdminLoginData'));
        if (AdminLoginData != null) {
            var token = AdminLoginData.token;
                let headers = new HttpHeaders({
           //   'Content-Type': 'application/pdf',
                Authorization: 'Bearer ' + token,
                });
            return headers;
        }
    }
    
    setTokenHeaderPdf() {
        var AdminLoginData = JSON.parse(localStorage.getItem('AdminLoginData'));
        if (AdminLoginData != null) {
            var token = AdminLoginData.token;
            let headers = new HttpHeaders({
                Authorization: 'Bearer ' + token,
            })
            return headers;
        }
    }

    getPermission(value) {
        if (this.permission != undefined) {
            if (this.permission.indexOf(value) !== -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }
}
