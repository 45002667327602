import {Injectable} from '@angular/core';

@Injectable()
export class PdcService {
    public  PdcData:any;
    public  permission:any;
    constructor() {
    }

}

