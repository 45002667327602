import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionService} from './session.service';


@Injectable({
  providedIn: 'root'
})
export class SportsServiceService {

  baseurl = this.sessionService.baseurl;

  constructor(private http: HttpClient, private sessionService: SessionService) {
  }

  createSports(user): Observable<any> {
    return this.http.post(this.baseurl + 'createSports', user, {headers: this.sessionService.setTokenHeader()});

  }

  getAllSports(params): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'getAllSports', params, {headers: this.sessionService.setTokenHeader()});

  }
  betRollback(params): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'bet/rollbackBetsByUser', params, {headers: this.sessionService.setTokenHeader()});

  }
  getTvUrl(): Observable<any> {
    return this.http.get<any>(this.baseurl +'gettvurl',{headers: this.sessionService.setTokenHeader()});
}
  getBookmakerAllowedSports(params): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'sports/getBookmakerAllowedSports', params, {headers: this.sessionService.setTokenHeader()});

  }
  statusUpdateSport(user): Observable<any> {
    return this.http.post(this.baseurl + 'updateSportsStatus', user, {headers: this.sessionService.setTokenHeader()});

  }

  statusUpdateSeries(user): Observable<any> {
    return this.http.post(this.baseurl + 'updateSeriesStatus', user, {headers: this.sessionService.setTokenHeader()});

  }
  getMatchname (): Observable<any> {
    return this.http.get<any>(  'https://score.crakex.in:3290/tv_code');
  }
  createSeries(user): Observable<any> {

    return this.http.post(this.baseurl + 'createSeries', user, {headers: this.sessionService.setTokenHeader()});

  }

  getAllSeries(param): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'getAllSeries', param, {headers: this.sessionService.setTokenHeader()});

  }

  createMatches(user): Observable<any> {
    return this.http.post(this.baseurl + 'createMatches', user, {headers: this.sessionService.setTokenHeader()});

  }

  getAllMatches(params): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'getAllMatches', params, {headers: this.sessionService.setTokenHeader()});

  }

  statusUpdateMatch(user): Observable<any> {
    return this.http.post(this.baseurl + 'updateMatchStatus', user, {headers: this.sessionService.setTokenHeader()});

  }

  createMarket(user): Observable<any> {
    return this.http.post(this.baseurl + 'createMarket', user, {headers: this.sessionService.setTokenHeader()});

  }

  getAllMarket(param): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'getAllMarket', param, {headers: this.sessionService.setTokenHeader()});

  }
  getMarketByMatch(param): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'market/getMarketByMatch', param, {headers: this.sessionService.setTokenHeader()});

  }
  deleteMarketNoHaveProfitLoss(): Observable<any> {
    return this.http.get(this.baseurl + 'market/deleteMarketNoHaveProfitLoss', {headers: this.sessionService.setTokenHeader()});

  }

  updateManualOddsStatus(param): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'market/updateManualOddsStatus', param, {headers: this.sessionService.setTokenHeader()});
  }
  createBookMakerMarket(param): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'market/createBookMakerMarket', param, {headers: this.sessionService.setTokenHeader()});

  }
  saveManualOdds(param): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'market/saveManualOdds', param, {headers: this.sessionService.setTokenHeader()});

  }
  getManualOddsMarketByMatch(param): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.post(this.baseurl + 'matches/getManualOddsMarketByMatch', param, {headers: this.sessionService.setTokenHeader()});

  }

  statusUpdateMarket(user): Observable<any> {
    return this.http.post(this.baseurl + 'updateMarketStatus', user, {headers: this.sessionService.setTokenHeader()});

  }

  GetSeriesLists(SportId): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getOnlineSeries', SportId, {headers: this.sessionService.setTokenHeader()});
  }

  getOnlineMatch(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getOnlineMatch', params, {headers: this.sessionService.setTokenHeader()});
  }

  getOnlineMarket(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getOnlineMarket', params, {headers: this.sessionService.setTokenHeader()});
  }

  createFancy(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'createFancy', params, {headers: this.sessionService.setTokenHeader()});
  }

  getFancy(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getFancy', params, {headers: this.sessionService.setTokenHeader()});
  }

  getFancyDetail(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancy/getFancyDetail', params, {headers: this.sessionService.setTokenHeader()});
  }

  changeFancyMode(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancy/changeFancyMode', params, {headers: this.sessionService.setTokenHeader()});
  }

  getIndianFancy(params): Observable<any> {
    return this.http.get<any>(this.baseurl + 'getIndianFancy?match_id=' + params, {headers: this.sessionService.setTokenHeader()});
  }

  updateMatch(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateMatch', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateMarket(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateMarket', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateGlobalSetting(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateGlobalSetting', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateSportsSetting(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateSportsSetting', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateFancyById(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateFancyById', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateFancyData(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateFancyData', params, {headers: this.sessionService.setTokenHeader()});
  }
  updateRatePointDiff(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancy/updateRatePointDiff', params, {headers: this.sessionService.setTokenHeader()});
  }
  updateFancyOdds(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancy/updateFancyOdds', params, {headers: this.sessionService.setTokenHeader()});
  }
  createManualFancy(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancy/createManualFancy', params, {headers: this.sessionService.setTokenHeader()});
  }
  createManualMarket(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'market/createManualMarket', params, {headers: this.sessionService.setTokenHeader()});
  }
  createUserSettings(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateUserSettings', params, {headers: this.sessionService.setTokenHeader()});
  }

  getMarketSettingById(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getMarketSettingById', params, {headers: this.sessionService.setTokenHeader()});
  }

  getMatchSettingById(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getMatchSettingById', params, {headers: this.sessionService.setTokenHeader()});
  }

  getHistory(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/getHistory', params, {headers: this.sessionService.setTokenHeader()});
  }
  getHistoryPdf(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/getHistory', params, {headers: this.sessionService.setTokenHeaderPdf(), responseType : 'arraybuffer' as 'json' });
  }
  getaccountSatement(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'accountSatement', params, {headers: this.sessionService.setTokenHeader()   });
  }
  getaccountSatementforPdf(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'accountSatement', params, {headers: this.sessionService.setTokenHeaderPdf(), responseType : 'arraybuffer' as 'json' });
  }



  getProfitLoss(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getProfitLoss', params, {headers: this.sessionService.setTokenHeader()});
  }

  getPartnershipList(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getPartnershipList', params, {headers: this.sessionService.setTokenHeader()});
  }

  updatePartnershipList(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updatePartnership', params, {headers: this.sessionService.setTokenHeader()});
  }

  getGlobalSetting(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'getGlobalSetting', {headers: this.sessionService.setTokenHeader()});
  }

  marketWatch(sportid): Observable<any> {
    return this.http.get<any>(this.baseurl + 'marketWatch?sport_id=' + sportid, {headers: this.sessionService.setTokenHeader()});
  }

  matchDetails(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'matchDetails', params, {headers: this.sessionService.setTokenHeader()});
  }

  profitLossMatchWise(params): Observable<any> {
    return this.http.post<any>(this.baseurl + '/report/profitLossMatchWise', params, {headers: this.sessionService.setTokenHeader()});
  }
  profitLossMatchWisePdf(params): Observable<any> {
    return this.http.post<any>(this.baseurl + '/report/profitLossMatchWise', params, {headers: this.sessionService.setTokenHeaderPdf(), responseType : 'arraybuffer' as 'json' });
  }

  profitLossUpline(params): Observable<any> {
    return this.http.post<any>(this.baseurl + '/report/profitLossUpline', params, {headers: this.sessionService.setTokenHeader()});
  }

  profitLossUplinePdf(params): Observable<any> {
    return this.http.post<any>(this.baseurl + '/report/profitLossUpline', params, {headers: this.sessionService.setTokenHeaderPdf(), responseType : 'arraybuffer' as 'json'});
  }

  profitLossUplineBySport(params): Observable<any> {
    return this.http.post<any>(this.baseurl + '/report/profitLossUplineBySport', params, {headers: this.sessionService.setTokenHeader()});
  }

  getBetsByMarketId(params): Observable<any> {
    return this.http.post<any>(this.baseurl + '/bet/getBetsByMarketId', params, {headers: this.sessionService.setTokenHeader()});
  }

  getBetsByMatchId(params): Observable<any> {
    return this.http.post<any>(this.baseurl + '/bet/getBetsByMatchId', params, {headers: this.sessionService.setTokenHeader()});
  }

  getFancyById(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'getFancyById?fancy_id=' + id, {headers: this.sessionService.setTokenHeader()});
  }

  removeOldData(param): Observable<any> {
    return this.http.post<any>(this.baseurl + 'deleteUserAndBet', param, {headers: this.sessionService.setTokenHeader()});
  }

  removeDataDatewise(param): Observable<any> {
    return this.http.post<any>(this.baseurl + 'removeDataDatewise', param, {headers: this.sessionService.setTokenHeader()});
  }

  getUserSettingsBySportID(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'getUserSettingsBySportId?user_id=' + id, {headers: this.sessionService.setTokenHeader()});
  }

  getSportSetting(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'getSportSetting/' + id, {headers: this.sessionService.setTokenHeader()});
  }

  getMatchAndMarketPosition(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'getMatchAndMarketPosition/' + id, {headers: this.sessionService.setTokenHeader()});
  }

  getundeclearedMarkets(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'undeclearedMarkets', {headers: this.sessionService.setTokenHeader()});
  }

  getdeclearedMarkets(param): Observable<any> {
    return this.http.post<any>(this.baseurl + 'declearedMarkets', param, {headers: this.sessionService.setTokenHeader()});
  }

  getActiveMatchesBySportId(param): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getActiveMatchesBySportId', param, {headers: this.sessionService.setTokenHeader()});
  }

  uploadlogo(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'uploadlogo', params, {headers: this.sessionService.setTokenHeader()});
  }

  uploadfavicon(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'uploadfavicon', params, {headers: this.sessionService.setTokenHeader()});
  }

  uploadSocialIcon(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'uploadSocialIcon', params, {headers: this.sessionService.setTokenHeader()});
  }

  getMatchUser(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getMatchUser', params, {headers: this.sessionService.setTokenHeader()});
  }

  matchResultOdds(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/matchResultOdds', params, {headers: this.sessionService.setTokenHeader()});
  }

  matchResultFancy(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'matchResultFancy', params, {headers: this.sessionService.setTokenHeader()});
  }

  abandonedFancy(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'abandonedFancy', params, {headers: this.sessionService.setTokenHeader()});
  }

  getMarketSelections(params): Observable<any> {
    return this.http.get<any>(this.baseurl + 'getMarketSelections?marketId=' + params, {headers: this.sessionService.setTokenHeader()});
  }

  marketRollback(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'marketRollback', params, {headers: this.sessionService.setTokenHeader()});
  }

  fancyRollback(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancyRollback', params, {headers: this.sessionService.setTokenHeader()});
  }

  getAllBets(): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/getAllBets', '', {headers: this.sessionService.setTokenHeader()});
  }

  deleteBet(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/deleteBet', params, {headers: this.sessionService.setTokenHeader()});
  }

  getRunTimeFancyPosition(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getRunTimeFancyPosition', params, {headers: this.sessionService.setTokenHeader()});
  }

  abandonedMarket(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'market/abandonedMarket', params, {headers: this.sessionService.setTokenHeader()});
  }

  getAllBetsByMatchWithSearch(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/getAllBetsByMatchWithSearch', params, {headers: this.sessionService.setTokenHeader()});
  }

  getDeletedBets(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/getDeletedBets', params, {headers: this.sessionService.setTokenHeader()});
  }

  getBetsByMatchAndUser(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'bet/getBetsByMarketAndUser', params, {headers: this.sessionService.setTokenHeader()});
  }

  settlementReport(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'report/settlementReport', params, {headers: this.sessionService.setTokenHeader()});
  }

  settlementHistoryByParent(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'report/settlementHistoryByParent', params, {headers: this.sessionService.setTokenHeader()});
  }

  makeSettlement(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'report/makeSettlement', params, {headers: this.sessionService.setTokenHeader()});
  }

  settlementCollectionHistory(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'report/settlementCollectionHistory', params, {headers: this.sessionService.setTokenHeader()});
  }

  autoResultDeclare(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'autoResultDeclare', {headers: this.sessionService.setTokenHeader()});
  }

  GetScoreApi(matchId): Observable<any> {
    return this.http.post<any>(this.baseurl + 'scoreBoard', matchId, {headers: this.sessionService.setTokenHeader()});
  }

  searchUserForAutoSuggest(search): Observable<any> {
    return this.http.post<any>(this.baseurl + 'user/searchUserForAutoSuggest', search, {headers: this.sessionService.setTokenHeader()});
  }

  matchListForKey(): Observable<any> {
    return this.http.get<any>(this.baseurl +'matchlist', {headers: this.sessionService.setTokenHeader()});
  }

  deleteSettlement(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'report/deleteSettlement', data, {headers: this.sessionService.setTokenHeader()});
  }

  setFancyRiskManagement(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancy/setFancyRiskManagement', data, {headers: this.sessionService.setTokenHeader()});
  }

  updateMarketVisibility(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'market/updateMarketVisibility', data, {headers: this.sessionService.setTokenHeader()});
  }

  updateApkVersion(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'apk/updateApkVersion', data, {headers: this.sessionService.setTokenHeader()});
  }

  changeIsActiveStatus(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'apk/changeIsActiveStatus', data, {headers: this.sessionService.setTokenHeader()});
  }

  getApiData(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'apk/getApkData', {headers: this.sessionService.setTokenHeader()});
  }

  teenPattiMarketResult(loginUserData): Observable<any> {
    return this.http.post<any>(this.baseurl + 'result/teenPattiMarketResult', loginUserData, {headers: this.sessionService.setTokenHeader()});
  }

  updateDeactiveFancyStatus(loginUserData): Observable<any> {
    return this.http.post<any>(this.baseurl + 'fancy/updateDeactiveFancyStatus', loginUserData, {headers: this.sessionService.setTokenHeader()});
  }

  superAdminCommissionDetail(loginUserData): Observable<any> {
    return this.http.post<any>(this.baseurl + 'report/superAdminCommissionDetail', loginUserData, {headers: this.sessionService.setTokenHeader()});
  }

  globalConstant(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'globalConstant', {headers: this.sessionService.setTokenHeader()});
  }

  marketResultListByMatchId(loginUserData): Observable<any> {
    return this.http.post<any>(this.baseurl + 'result/marketResultListByMatchId', loginUserData, {headers: this.sessionService.setTokenHeader()});
  }

  marketResultListByMatchIdWithOutPagination(loginUserData): Observable<any> {
    return this.http.post<any>(this.baseurl + 'result/marketResultListByMatchIdWithOutPagination', loginUserData, {headers: this.sessionService.setTokenHeader()});
  }

  getLiveSportMatchList(loginUserData): Observable<any> {
    return this.http.post<any>(this.baseurl + 'matches/getLiveSportMatchList', loginUserData, {headers: this.sessionService.setTokenHeader()});
  }


  GetUserRoleType(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'subAdminRoles/permissions', {headers: this.sessionService.setTokenHeader()});
  }

  GetUserRole(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'subAdminRoles/list', {headers: this.sessionService.setTokenHeader()});
  }

  showRoleSetting(userid): Observable<any> {
    return this.http.post(this.baseurl + 'subAdminRoles/findById', userid, {headers: this.sessionService.setTokenHeader()});

  }

  submitForm_Role(addUser): Observable<any> {
    return this.http.post(this.baseurl + 'subAdminRoles/save', addUser, {headers: this.sessionService.setTokenHeader()});

  }

  subAdminRole(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'subAdmin/list', {headers: this.sessionService.setTokenHeader()});
  }

  updateSubAdmin(update): Observable<any> {
    return this.http.post(this.baseurl + 'subAdmin/update', update, {headers: this.sessionService.setTokenHeader()});

  }

  statusUpdateRole(updateStatus): Observable<any> {
    return this.http.post(this.baseurl + 'subAdmin/saveStatus', updateStatus, {headers: this.sessionService.setTokenHeader()});

  }

  deleteAdminRole(deleteRole): Observable<any> {
    return this.http.post(this.baseurl + 'subAdmin/delete', deleteRole, {headers: this.sessionService.setTokenHeader()});

  }

  deleteRole(deleteRole): Observable<any> {
    return this.http.post(this.baseurl + 'subAdminRoles/delete_role', deleteRole, {headers: this.sessionService.setTokenHeader()});

  }

  subadmin_Role(saveRole): Observable<any> {
    return this.http.post(this.baseurl + 'subAdmin/save', saveRole, {headers: this.sessionService.setTokenHeader()});

  }
    permanentDeleteBet(saveRole): Observable<any> {
    return this.http.post(this.baseurl + 'bet/permanentDeleteBet', saveRole, {headers: this.sessionService.setTokenHeader()});

  }

}
