import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionService} from './session.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  baseurl = this.sessionService.baseurl;

  constructor(private http: HttpClient, private sessionService: SessionService) {
  }

  getOwnChild(user): Observable<any> {
    return this.http.post(this.baseurl + 'getOwnChild', user, {headers: this.sessionService.setTokenHeader()});

  }
  getUserListMatchOddsBook(data): Observable<any> {
    return this.http.post(this.baseurl + 'matches/user-book', data, {headers: this.sessionService.setTokenHeader()});

  }

  userSearch(user): Observable<any> {
    return this.http.post(this.baseurl + 'user/search', user, {headers: this.sessionService.setTokenHeader()});

  }

  getUserBasicDetails(user): Observable<any> {
    return this.http.post(this.baseurl + 'getUserBasicDetails', user, {headers: this.sessionService.setTokenHeader()});

  }

  logout(): Observable<any> {
    return this.http.post(this.baseurl + 'logout', '', {headers: this.sessionService.setTokenHeader()});

  }

  addUsers(user): Observable<any> {

    return this.http.post(this.baseurl + 'register', user, {headers: this.sessionService.setTokenHeader()});

  }

  updatePassword(user): Observable<any> {

    return this.http.post(this.baseurl + 'updatePassword', user, {headers: this.sessionService.setTokenHeader()});

  }

  updateChildPassword(user): Observable<any> {

    return this.http.post(this.baseurl + 'updateChildPassword', user, {headers: this.sessionService.setTokenHeader()});

  }


  updateuserStatusLock(user): Observable<any> {

    return this.http.post(this.baseurl + 'updateuserStatusLock', user, {headers: this.sessionService.setTokenHeader()});

  }

  updateuserStatusUnlock(user): Observable<any> {
    return this.http.post(this.baseurl + 'updateuserStatusUnlock', user, {headers: this.sessionService.setTokenHeader()});

  }

  getClosedUserList(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'getClosedUserList', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateuserStatusClose(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateuserStatusClose', params, {headers: this.sessionService.setTokenHeader()});
  }
  deleteClosedAccount(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'user/deleteClosedAccount', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateuserStatusReopen(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateuserStatusReopen', params, {headers: this.sessionService.setTokenHeader()});
  }
  updateUserStatusBettingLock(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateUserStatusBettingLock', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateUserStatusBettingUnlock(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateUserStatusBettingUnlock', params, {headers: this.sessionService.setTokenHeader()});
  }
  updateUserStatusFancyBetLock(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateUserStatusFancyBetLock', params, {headers: this.sessionService.setTokenHeader()});
  }

  updateUserStatusFancyBetUnlock(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateUserStatusFancyBetUnlock', params, {headers: this.sessionService.setTokenHeader()});
  }
  updateUserBasicDetails(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'updateUserBasicDetails', params, {headers: this.sessionService.setTokenHeader()});
  }
  accountChildInOut(params): Observable<any> {
    return this.http.post<any>(this.baseurl + 'chipInOut', params, {headers: this.sessionService.setTokenHeader()});
  }

  getChipDatabyId(): Observable<any> {
    return this.http.get<any>(this.baseurl+'getUserBalance' , {headers: this.sessionService.setTokenHeader()});
  }
  logoutAllUser(): Observable<any> {
    return this.http.post<any>(this.baseurl+'user/logoutAllUser' , '',{headers: this.sessionService.setTokenHeader()});
  }
  logoutPerticularUser(param): Observable<any> {
    return this.http.post<any>(this.baseurl+'user/selectedUserLogout' , param,{headers: this.sessionService.setTokenHeader()});
  }
  updateUserStatusSettlementLock(param): Observable<any> {
    return this.http.post<any>(this.baseurl+'user/updateUserStatusSettlementLock' , param,{headers: this.sessionService.setTokenHeader()});
  }
  updateUserStatusSettlementUnlock(param): Observable<any> {
    return this.http.post<any>(this.baseurl+'user/updateUserStatusSettlementUnlock' , param,{headers: this.sessionService.setTokenHeader()});
  }
  getOnlineUsers(params): Observable<any> {
    return this.http.post<any>(this.baseurl+'user/getOnlineUsers' , params,{headers: this.sessionService.setTokenHeader()});
  }
  saveNotification(param): Observable<any> {
    return this.http.post<any>(this.baseurl+'notifications/saveNotification' , param,{headers: this.sessionService.setTokenHeader()});
  }
  getAllNotifications(): Observable<any> {
    return this.http.post<any>(this.baseurl+'notifications/getAllNotifications' , '',{headers: this.sessionService.setTokenHeader()});
  }
  updateNotificationStatus(param): Observable<any> {
    return this.http.post<any>(this.baseurl+'notifications/updateNotificationStatus' , param,{headers: this.sessionService.setTokenHeader()});
  }
  check_user_name(param): Observable<any> {
    return this.http.post<any>(this.baseurl+'check_user_name' , param,{headers: this.sessionService.setTokenHeader()});
  }
  check_user_name1(param): Observable<any> {
    return this.http.post<any>(this.baseurl+'subAdminRoles/check_role_name' , param,{headers: this.sessionService.setTokenHeader()});
  }
}

