import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {SessionService} from './session.service';


@Injectable({
  providedIn: 'root'
})

export class LoginServiceService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }
  baseurl = this.sessionService.baseurl;

  UserLogin(user): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', "application/json");
    return this.http.post(this.baseurl + 'login',user);
  }

}
